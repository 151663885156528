.avatar {
	@apply object-cover shrink-0;
}

.avatar > img {
	@apply rounded-full bg-center bg-cover bg-gray-100 dark:bg-gray-800;
}

.avatar-rounded > img {
	@apply rounded !shadow-none !bg-transparent;
}

.avatar > i {
	@apply absolute rounded-full bg-green-400 ring-white dark:ring-dark;
}

.avatar-sm {
	width: theme('avatar.size.sm');
	height: theme('avatar.size.sm');
	min-width: theme('avatar.size.sm');
}

.avatar-xxs {
	width: theme('avatar.size.xxs');
	height: theme('avatar.size.xxs');
	min-width: theme('avatar.size.xxs');
}

.avatar-xs {
	width: theme('avatar.size.xs');
	height: theme('avatar.size.xs');
	min-width: theme('avatar.size.xs');
}

.avatar-md {
	width: theme('avatar.size.md');
	height: theme('avatar.size.md');
	min-width: theme('avatar.size.md');
}

.avatar-lg {
	width: theme('avatar.size.lg');
	height: theme('avatar.size.lg');
	min-width: theme('avatar.size.lg');
}

.avatar-md img {
	@apply shadow-md;
}

.avatar-sm img {
	@apply shadow-sm;
}

.avatar-lg img {
	@apply shadow-lg;
}

.avatar-xl img {
	@apply shadow-lg ring-2 ring-white dark:ring-dark;
}

.avatar-xs i {
	@apply right-0 bottom-0 w-2 h-2 ring-1;
}

.avatar-sm i {
	@apply right-0 bottom-0 w-2.5 h-2.5 ring-1;
}

.avatar-md i {
	@apply right-0.5 bottom-0.5 w-4 h-4 ring-2;
}

.avatar-lg i {
	@apply right-1 bottom-1 w-5 h-5 ring-2 shadow-lg;
}

.avatar-xl i {
	@apply right-2 bottom-2 w-6 h-6 ring-2;
}
