.emoji-picker {
	@apply overflow-hidden;
}

.emoji-categories {
	@apply flex p-1 border-b sticky top-0 z-[2] gap-1 bg-white dark:bg-dark dark:fill-light;
}

.emoji-canvas {
	@apply text-xl grid text-center grid-cols-8 w-max p-2 gap-1;
}

.emoji-button {
	@apply w-7 h-7 bg-center bg-no-repeat shrink-0 cursor-pointer transition-transform hover:scale-150;
}
