.spinner-border {
	@apply inline-block animate-spin rounded-full box-border border-solid border-gray-400 !border-t-transparent border-4 h-8 w-8;
}

.data-list > option {
	@apply bg-white border border-gray-200 text-dark my-2 px-6 py-2 w-full;
}

.data-list > option:last-child {
	@apply overflow-y-auto bg-white rounded-lg border border-gray-200 text-dark my-2 rounded-b-lg;
}

time {
	@apply text-muted text-xs whitespace-nowrap;
}
