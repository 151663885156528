.navbar {
	@apply flex w-full items-center h-12 shadow-sm z-[99] bg-white dark:bg-black transition-[top] duration-300 ease-in-out;
}

.navbar a.active {
	@apply text-primary transition-colors duration-300 ease-out;
}

.appbar {
	@apply px-3 shadow-sm sticky top-0 md:bg-gray-100/80 dark:md:bg-black/50 md:!shadow-none md:!backdrop-blur;
}

.sidenav-brand {
	@apply !flex flex-nowrap gap-3 items-center py-3 px-3 overflow-hidden whitespace-nowrap mb-2;
}

.sidenav-link {
	@apply flex flex-nowrap gap-3 items-center p-3 overflow-hidden text-ellipsis whitespace-nowrap w-fit rounded-full text-dark hover:text-dark hover:bg-gray-200 dark:text-light dark:hover:bg-gray-800 transition-all duration-300;
}

.sidenav-link.active {
	@apply text-primary;
}

.bottombar {
	@apply navbar fixed bottom-0 transition-all duration-1000 ease-in-out;
}

.badge-round,
.appbar-btn .badge,
.bottombar .badge,
.sidenav .badge {
	@apply absolute -top-1 bg-danger left-3 !rounded-full text-xs min-w-fit w-5 h-5 py-0.5;
}

.listlink {
	@apply flex items-center gap-4 cursor-pointer hover:!text-primary text-lg;
}

.listlink-icon {
	@apply h-12 w-12 rounded-full p-2 shadow-md bg-white dark:bg-gray-800;
}

.scroll-button {
	@apply z-[2000] absolute top-10 right-0 shadow-md !bg-white dark:!bg-gray-700;
}
