.stack {
	@apply flex self-stretch;
}

.hstack {
	@apply stack flex-row min-w-0 items-center;
}

.vstack {
	@apply stack flex-col flex-auto;
}

.center {
	@apply stack justify-center items-center;
}
