button {
	@apply flex flex-row gap-x-1 justify-center items-center font-medium rounded transition duration-150 ease-in-out gap-1;
}

button.disabled {
	@apply !pointer-events-none bg-gray-200 dark:bg-gray-800 !text-gray-600 opacity-80 cursor-default;
}

button.round {
	@apply !rounded-full;
}

button.small {
	@apply px-3 py-1.5 text-xs;
}
button.default {
	@apply px-4 py-2 text-sm;
}
button.rect {
	@apply p-2;
}
button.big {
	@apply px-6 py-2.5 text-base;
}

button.success {
	@apply !bg-green-500 hover:!bg-green-400 focus:!bg-green-400 text-white;
}

button.warning {
	@apply !bg-warning hover:!bg-warning focus:!bg-warning text-white;
}

button.success-fill {
	@apply !bg-transparent hover:!text-green-600 hover:!fill-green-600 focus:!fill-green-700 focus:!text-green-700;
}

button.danger {
	@apply !bg-red-600 hover:!bg-red-700 focus:!bg-red-800 text-white;
}

button.transparent {
	@apply bg-transparent hover:bg-gray-200 focus:bg-gray-300 text-black dark:text-light dark:hover:bg-gray-700 dark:focus:bg-gray-600;
}

button.secondary {
	@apply bg-gray-200 hover:bg-gray-300 focus:bg-gray-300 text-black dark:bg-gray-800 dark:text-light dark:hover:bg-gray-700 dark:focus:bg-gray-600;
}

button.danger-transparent {
	@apply bg-transparent hover:!bg-red-100 focus:!bg-red-200 text-red-600;
}

button.inverted {
	@apply bg-white hover:bg-gray-200 focus:bg-gray-200 text-primary;
}
