.wizard {
	@apply flex flex-col flex-auto gap-2 self-stretch justify-between;
}

.wizard-header {
	@apply flex min-w-0 w-full shrink-0;
}

.wizard-body {
	@apply flex flex-col w-full grow overflow-y-auto max-h-[calc(100svh-14rem)];
}

.wizard-footer {
	@apply flex shrink-0 min-w-0 w-full justify-end pt-4 mt-1 border-t;
}
