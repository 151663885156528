.html-block a {
	@apply text-primary underline;
}

.html-block ul {
	@apply my-4 list-disc ml-8;
}

.html-block ol {
	@apply list-decimal ml-6;
}

.html-block > ol ol {
	@apply list-[upper-latin];
}

.html-block > ol ol ol {
	@apply list-[lower-latin];
}

.html-block li {
	@apply my-1 last:mb-0;
}
