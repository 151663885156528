.popover-new {
	@apply absolute p-1 shadow-md min-w-full w-auto rounded z-[4] overflow-auto border bg-white dark:bg-dark;
}

.popover-new .rdp-table {
	@apply w-full text-center;
}

.popover-new .rdp-head_cell {
	@apply leading-8;
}
