.loader-line {
	@apply sticky z-[1000]  top-12;
}

.loader-line span {
	@apply absolute block h-[2px] -top-px w-full md:bg-gray-50;
}

.loader-line span:before {
	@apply block bg-gray-300 h-[2px] w-0;
	content: '';
	animation: loaderWidth 3s ease-in infinite;
}

@keyframes loaderWidth {
	100% {
		width: 100%;
	}
}
