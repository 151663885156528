.options {
	@apply absolute p-1 shadow-md rounded z-[4] overflow-auto border bg-white dark:bg-dark min-w-full w-auto;
}

.options > .option {
	@apply text-sm rounded-none hover:rounded justify-start truncate w-full;
}

.options > .option.selected {
	@apply !text-primary;
}

.options > hr {
	@apply my-1;
}
