.modal-wrap {
	@apply bg-gray-300 bg-opacity-90 dark:bg-gray-800 dark:bg-opacity-90 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto z-[1055];
}

.modal-content {
	@apply flex flex-col border-none shadow-lg relative w-full pointer-events-auto bg-white dark:bg-dark bg-clip-padding rounded-md outline-none;
}

.modal-header {
	@apply flex shrink-0 items-center justify-between h-[3rem] border-b rounded-t-md text-lg font-semibold pr-2 pl-4;
}

.modal-body {
	@apply relative p-4;
}

.modal-footer {
	@apply flex flex-shrink-0 items-center justify-end p-4 gap-4 border-t border-gray-200 rounded-b-md;
}

.modal-dialog {
	@apply flex w-auto pointer-events-none mx-auto sm:p-2 max-w-xl min-h-full items-center;
}

.modal-scrollable {
	@apply items-start;
}

.modal-scrollable .modal-body {
	@apply overflow-y-auto max-h-[calc(100svh-3rem)] sm:max-h-[calc(100svh-6rem)];
}

.modal-fullscreen .modal-header {
	@apply rounded-none;
}

.modal-fullscreen .modal-body,
.modal-fullscreen .modal-content {
	@apply rounded-none min-h-screen;
}

.modal-preview {
	@apply modal-dialog p-0 w-full sm:!max-w-2xl;
}

.modal-preview .modal-content {
	@apply sm:shadow-lg sm:rounded-md bg-transparent;
}

.modal-preview .modal-body {
	@apply flex flex-col grow m-0 p-0 min-h-screen md:min-h-full;
}
