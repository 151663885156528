.card {
	@apply block md:rounded-lg shadow-sm bg-white dark:bg-dark;
}

.card + .card {
	@apply mt-2;
}

.card > .card {
	@apply border-t rounded-t-none border-gray-200 dark:border-gray-800;
}

.card-header {
	@apply p-4 flex flex-row items-center justify-between;
}

.card-body {
	@apply px-4 py-3 w-full;
}

.card-header + .card-body {
	@apply px-4 py-0;
}

.card-footer {
	@apply px-4 py-3;
}
