.nav-scroll-container {
	@apply block overflow-x-scroll p-2 hide-scrollbar;
}

.nav.nav-tabs {
	@apply block w-auto min-w-full whitespace-nowrap gap-8;
}

.nav-item {
	@apply inline-block mx-1;
}

.nav-item:first-child {
	@apply mx-0;
}

.nav-item:last-child {
	@apply mx-0;
}

.nav-item .nav-link {
	@apply block leading-tight px-1 py-1 hover:cursor-pointer bg-transparent hover:text-hover;
}

.nav-item .nav-link.active {
	@apply !text-green-500 border-b-2 !border-green-500;
}

.nav-scroll-container {
	@apply z-50;
}

.tabs {
}

.tab-pane {
	@apply grow;
}

.tab-content {
	@apply overflow-hidden;
}
