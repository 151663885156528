.input {
	@apply border rounded transition-colors duration-150 ease-in-out flex text-black overflow-hidden bg-gray-50 dark:bg-black text-dark dark:text-light;
}

.input.round {
	@apply !rounded-full;
}

.input.default input {
	@apply !px-4 !py-2 text-sm;
}

.input.big input {
	@apply !px-4 !py-2.5 text-base;
}

.input.small input {
	@apply !px-3 !py-1.5 text-xs;
}

.input.disabled {
	@apply !pointer-events-none bg-gray-300 !text-muted opacity-70 dark:bg-black;
}

.input input {
	@apply bg-transparent w-full text-inherit placeholder:text-muted;
}

.input.danger {
	@apply bg-red-100 border-red-300 hover:!border-red-400 focus:!border-red-500 dark:bg-black;
}

.input.danger input {
	@apply placeholder:text-red-300 text-red-300;
}

.input.main {
	@apply bg-gray-50 hover:border-gray-300 focus-within:!border-muted dark:bg-black dark:hover:border-muted dark:text-light;
}

input[type='date'] {
	display: block;
	-webkit-appearance: none;
	-moz-appearance: none;
	min-height: 2rem;
}

.pin-input {
	@apply flex gap-2 text-xl font-medium;
}

.pin-input input {
	@apply input ring-0 focus-within:!border-muted py-3 text-center border rounded flex-1;
}
