.form-control {
	@apply block text-base w-full px-3 py-1.5 font-normal bg-clip-padding border border-solid rounded transition ease-in-out m-0 focus:border-gray-400 focus:outline-none focus:shadow-none my-2 text-dark bg-white dark:text-light dark:bg-black;
}

/* Input */

.input-text {
	@apply form-control;
}

.input-text-sm {
	@apply !px-2 !py-1 !text-sm;
}

.input-text-error {
	@apply !border-red-500;
}

/* Select */

.selector {
	@apply form-control;
}

.selector-sm {
	@apply !px-2 !py-1 !text-sm;
}

.selector-error {
	@apply !border-red-500;
}

/* ListGroup */

.wrap-list-group {
	@apply overflow-hidden;
}

.list-group {
	@apply overflow-y-auto bg-white rounded-lg border border-gray-200 text-gray-900;
}

.list-group-item:first-child {
	@apply px-6 py-2 border-b border-gray-200 w-full rounded-t-lg;
}

.list-group-item {
	@apply text-sm px-6 py-2 border-b border-gray-200 w-full hover:bg-gray-100 hover:text-primary hover:cursor-pointer;
}

.list-group-item:last-child {
	@apply px-6 py-2 w-full rounded-b-lg;
}

/* TextArea */

.text-area {
	@apply form-control;
}

.text-area-sm {
	@apply !px-2 !py-1 !text-sm;
}

.text-area-error {
	@apply !border-red-500;
}

/* Checkbox */

.checkbox {
	@apply appearance-none h-4 w-4 border rounded bg-white dark:bg-black checked:bg-primary dark:checked:bg-primary checked:border-primary focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer;
}

.checkbox-sm {
	@apply !px-2 !py-1 !text-sm;
}

.checkbox-error {
	@apply !border-red-500;
}

.checkbox-label {
	@apply text-sm;
}

.checkbox-label-error {
	@apply !text-red-500;
}
