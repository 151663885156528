.paper {
	@apply min-h-full md:rounded-lg shadow-sm bg-white dark:bg-dark p-4 md:mb-2;
}

.paper-header {
	@apply flex border-b;
}

.paper-footer {
	@apply px-4 py-3 border-t-[1px] grid grid-cols-3 text-center text-xs;
}
