.accordions {
	@apply rounded border bg-white dark:bg-dark;
}
.accordions-item {
	@apply w-full border-b last:border-none;
}
.accordions-item > .content {
	@apply h-0 px-3 pb-3 mt-2 hidden;
}
.accordions-item.active > .content {
	@apply h-auto block;
}
